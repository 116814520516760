.onhoverbgnone:focus {
  background-color: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

.loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}
.animated-bg {
  border-radius: 4px;
  border: none;
  animation: loader 3s infinite linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #9ed7ff, #ffffff, #9ed7ff);
  background-size: 800px 50px;
  height: 20px;
  margin: 10px 0px;
}
@keyframes loader {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.react-tel-input {
  border: 2px solid white;
}

.react-tel-input .mobile-input {
  border: 2px solid white;
  margin: 20px;
  padding: 20px;
}

.special-label {
  display: none;
}

.react-tel-input .form-control {
  border: none;
}

.flag-dropdown {
  border: 1px solid gray;
  margin-right: 10px;
}

.custom-phone-input {
  display: flex;
  align-items: center;
}

.flag-and-code {
  display: flex;
  align-items: center;
}

.country-code {
  margin-right: 10px; /* Adjust as needed */
}

.phone-number input {
  border: 2px solid white;
  padding: 20px;
}
.phone-number .flag-dropdown {
  border: 2px solid white;
}

.react-tel-input .form-control {
  border: 1px solid #cbcbcb !important;
  border-radius: 9px !important;
  height: 48px !important;
  width: 100% !important;
  padding-left: 72px !important;
}

.react-tel-input .form-control input {
  width: 100% !important;
  height: 46px !important;
  color: #9ed7ff;
}

.react-tel-input .flag-dropdown {
  border: none;
  background-color: white !important;
  border-radius: 9px 0px 0px 9px !important;
}

.react-tel-input .selected-flag {
  width: 62px !important;
  padding: 0 0 0 21px !important;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 25px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.btn-login {
  background-color: transparent !important;
  border: 1px solid #7904eb !important;
  transition: 0.3s ease all !important;
  color: "black" !important;
  padding: 16px 44px !important;
}

.btn-login:hover {
  background-color: #7904eb !important;
  color: white !important;
}

/* otp css  */
@media screen and (max-width: 420px) {
  .opt-box input {
    height: 40px !important;
    width: 35px !important;
    margin: 0 5px !important ;
  }
}

.my_profile {
  color: black;
  font-size: 12px !important;
}

.logout {
  background: linear-gradient(90deg, #ab4cba 0%, #6f3ab1 100%, #5941ce 100.01%);
  padding: 5px;
  color: white;
  margin: 5px;
  border-radius: 5px;
}

.logout button {
  background-color: transparent !important;
  color: white;
  border: none;
}
